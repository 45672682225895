import { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'
import InputMask from 'react-input-mask'
import Steps from '../components/Steps'
import Loader from '../components/Loader'
import ApiService from '../services/ApiService'
import { Quote } from '../types'

export default function Questions() {
    const { register: registerQuestion, handleSubmit: handleQuestionsSubmit } =
        useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [usResident, setUsResident] = useState<boolean | null>(null)
    const [bestDescription, setBestDescription] = useState<string | null>(null)
    const [firstTimeRenter, setFirstTimeRenter] = useState<boolean | null>(
        false
    )
    const [nonUsBestDescription, setNonUsBestDescription] = useState<
        string | null
    >(null)

    const [upfrontDeposit, setUpfrontDeposit] = useState<boolean | null>(null)
    const [depositAmountDontKnow, setDepositAmountDontKnow] = useState<
        boolean | null
    >(null)
    const [depositDescription, setDepositDescription] = useState<string | null>(
        null
    )
    const [additionalDeposit, setAdditionalDeposit] = useState<boolean | null>(
        null
    )
    const [
        additionalCoverageAmountDontKnow,
        setAdditionalCoverageAmountDontKnow,
    ] = useState<boolean | null>(null)
    const [additionalCoverageCostDontKnow, setAdditionalCoverageCostDontKnow] =
        useState<boolean | null>(null)
    const [depositService, setDepositService] = useState<string | null>(null)

    const navigate = useNavigate()

    const infoPreviewContext = useInfoPreviewContext()
    const quoteContext = useQuoteContext()

    const infoPreview = infoPreviewContext!.infoPreview

    console.log('INFO PREVIEW', infoPreview)

    if (infoPreview === undefined) {
        //navigate('/start')
    }

    const bestDescriptionOptions = [
        { key: 'self-employed', label: 'Self Employed' },
        { key: 'student-retired', label: 'Student/Retired' },
        { key: 'relocating', label: 'Relocating from out of state' },
        { key: 'other', label: 'Other' },
    ]

    const nonUsBestDescriptionOptions = [
        { key: 'currently-employed', label: 'Currently Employed' },
        { key: 'student', label: 'Student' },
        { key: 'other', label: 'Other' },
    ]

    const depositDescriptionOptions = [
        { key: 'pet-deposit', label: 'Pet Deposit' },
        {
            key: 'refundable-security-deposit',
            label: 'Refundable Security Deposit',
        },
        { key: 'last-months-rent', label: 'Last Month’s rent' },
        { key: 'other', label: 'Other' },
    ]

    const depositServiceOptions = [
        { key: 'obligo', label: 'Obligo' },
        {
            key: 'rhino',
            label: 'Rhino',
        },
        { key: 'i-dont-know', label: "I don't know" },
        { key: 'other', label: 'Other' },
    ]

    const onQuestionsSubmit = async (data: FieldValues) => {
        setLoading(true)

        try {
            const response = await ApiService.post<Quote>('/quote', {
                email: infoPreview?.email,
                usResident: data.usResident,
                firstTimeRenter: false,
                bestDescription: data.bestDescription,
                nonUsBestDescription: data.nonUsBestDescription,
                annualIncome: data.annualIncome,
                monthlyRent: data.monthlyRent,
                ssn: data.ssn,
                upfrontDeposit: data.upfrontDeposit,
                depositDescription: data.depositDescription,
                depositDescriptionText: data.depositDescriptionText,
                depositAmount: data.depositAmount,
                depositAmountDontKnow: data.depositAmountDontKnow,
                additionalCoverageAmount: data.additionalCoverageAmount,
                additionalCoverageAmountDontKnow:
                    data.additionalCoverageAmountDontKnow,
                additionalCoverageCost: data.additionalCoverageCost,
                additionalCoverageCostDontKnow:
                    data.additionalCoverageCostDontKnow,
                depositService: data.depositService,
                depositServiceText: data.depositServiceText,
            })

            setLoading(false)
            setErrorMessage(null)
            quoteContext!.update(response)
            navigate(
                infoPreview?.connectedBankAccount ? '/quote' : '/bank-account'
            )
        } catch (error) {
            setErrorMessage(
                'We are unable to offer you coverage at this time. We apologize for the inconvenience. Please contact your property manager for additional information on next steps.'
            )
            setLoading(false)
        }
    }

    if (infoPreview === undefined) {
        return <Navigate to="/start" />
    }

    return (
        <>
            <Steps active={2} />
            {loading ? (
                <Loader />
            ) : (
                <form
                    className="form"
                    onSubmit={handleQuestionsSubmit(onQuestionsSubmit)}
                >
                    {errorMessage !== null && (
                        <div className="error">{errorMessage}</div>
                    )}
                    <fieldset className="fieldset">
                        <h2>Are you a US resident?</h2>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="1"
                                {...registerQuestion('usResident')}
                                onClick={() => {
                                    setUsResident(true)
                                    setFirstTimeRenter(false)
                                }}
                            />{' '}
                            Yes
                        </label>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="0"
                                {...registerQuestion('usResident')}
                                onClick={() => {
                                    setUsResident(false)
                                    setFirstTimeRenter(false)
                                }}
                            />{' '}
                            No
                        </label>
                    </fieldset>
                    {usResident === true && (
                        <>
                            {firstTimeRenter === false && (
                                <fieldset className="fieldset">
                                    <h2>What best describes you?</h2>
                                    {bestDescriptionOptions.map((item, key) => (
                                        <label
                                            key={key}
                                            className="label label--radio"
                                        >
                                            <input
                                                type="radio"
                                                required={true}
                                                value={item.key}
                                                {...registerQuestion(
                                                    'bestDescription'
                                                )}
                                                onClick={() => {
                                                    setBestDescription(item.key)
                                                }}
                                            />{' '}
                                            {item.label}
                                        </label>
                                    ))}
                                </fieldset>
                            )}
                            {(firstTimeRenter === true ||
                                bestDescription !== null) && (
                                <>
                                    <fieldset className="fieldset">
                                        <h2>Your Social Security Number</h2>
                                        <label className="label">
                                            Please enter your SSN:
                                        </label>
                                        <InputMask
                                            mask="999-99-9999"
                                            {...registerQuestion('ssn')}
                                            placeholder="___-__-____"
                                            className="input"
                                        />
                                    </fieldset>
                                    <br />
                                </>
                            )}
                        </>
                    )}
                    {usResident === false && (
                        <>
                            <fieldset className="fieldset">
                                <h2>What best describes you?</h2>
                                {nonUsBestDescriptionOptions.map(
                                    (item, key) => (
                                        <label
                                            key={key}
                                            className="label label--radio"
                                        >
                                            <input
                                                type="radio"
                                                required={true}
                                                value={item.key}
                                                {...registerQuestion(
                                                    'nonUsBestDescription'
                                                )}
                                                onClick={() =>
                                                    setNonUsBestDescription(
                                                        item.label
                                                    )
                                                }
                                            />{' '}
                                            {item.label}
                                        </label>
                                    )
                                )}
                            </fieldset>

                            {nonUsBestDescription === 'Currently Employed' && (
                                <fieldset className="fieldset">
                                    <h3>
                                        We need some financial info to calculate
                                        your monthly price
                                    </h3>
                                    <fieldset className="fieldset">
                                        <label className="label">
                                            Annual Income (USD) *
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="number"
                                            {...registerQuestion(
                                                'annualIncome'
                                            )}
                                        />
                                    </fieldset>
                                    <fieldset className="fieldset">
                                        <label className="label">
                                            Monthly Rent (USD) *
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="number"
                                            {...registerQuestion('monthlyRent')}
                                        />
                                    </fieldset>
                                </fieldset>
                            )}
                        </>
                    )}
                    <fieldset className="fieldset">
                        <h3>
                            Are you required to provide an upfront deposit{' '}
                            <u>IN ADDITION TO</u> Lighthouse?
                        </h3>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="0"
                                {...registerQuestion('upfrontDeposit')}
                                onClick={() => {
                                    setUpfrontDeposit(false)
                                }}
                            />{' '}
                            No
                        </label>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="1"
                                {...registerQuestion('upfrontDeposit')}
                                onClick={() => {
                                    setUpfrontDeposit(true)
                                }}
                            />{' '}
                            Yes
                        </label>
                    </fieldset>
                    {upfrontDeposit === true && (
                        <>
                            <fieldset className="fieldset">
                                <h3>What best describes the deposit?</h3>
                                {depositDescriptionOptions.map((item, key) => (
                                    <>
                                        <label
                                            key={key}
                                            className="label label--radio"
                                        >
                                            <input
                                                type="radio"
                                                required={true}
                                                value={item.key}
                                                {...registerQuestion(
                                                    'depositDescription'
                                                )}
                                                onClick={() => {
                                                    setDepositDescription(
                                                        item.key
                                                    )
                                                }}
                                            />{' '}
                                            {item.label}
                                        </label>
                                    </>
                                ))}
                                {depositDescription === 'other' && (
                                    <>
                                        <label className="label">
                                            Description
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="text"
                                            {...registerQuestion(
                                                'depositDescriptionText'
                                            )}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}
                            </fieldset>
                            <fieldset className="fieldset">
                                <h3>How much is the deposit?</h3>
                                {depositAmountDontKnow !== true && (
                                    <>
                                        <label className="label">
                                            Amount (USD) *
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="number"
                                            {...registerQuestion(
                                                'depositAmount'
                                            )}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}

                                <label className="label label--radio">
                                    <input
                                        type="checkbox"
                                        required={false}
                                        value="1"
                                        {...registerQuestion(
                                            'depositAmountDontKnow'
                                        )}
                                        onChange={(event) => {
                                            setDepositAmountDontKnow(
                                                event.target.checked
                                            )
                                        }}
                                    />{' '}
                                    I don't know yet
                                </label>
                            </fieldset>
                        </>
                    )}

                    <fieldset className="fieldset">
                        <h3>
                            Are you required to sign up for <u>ADDITIONAL</u>{' '}
                            Security Deposit Alternatives like Lighthouse?
                        </h3>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="0"
                                {...registerQuestion('additionalDeposit')}
                                onClick={() => {
                                    setAdditionalDeposit(false)
                                }}
                            />{' '}
                            No
                        </label>
                        <label className="label label--radio">
                            <input
                                type="radio"
                                required={true}
                                value="1"
                                {...registerQuestion('additionalDeposit')}
                                onClick={() => {
                                    setAdditionalDeposit(true)
                                }}
                            />{' '}
                            Yes
                        </label>
                    </fieldset>
                    {additionalDeposit === true && (
                        <>
                            <fieldset className="fieldset">
                                <h3>
                                    How much <u>additional</u> coverage are they
                                    requiring?
                                </h3>
                                {additionalCoverageAmountDontKnow !== true && (
                                    <>
                                        <label className="label">
                                            Amount (USD) *
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="number"
                                            {...registerQuestion(
                                                'additionalCoverageAmount'
                                            )}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}

                                <label className="label label--radio">
                                    <input
                                        type="checkbox"
                                        required={false}
                                        value="1"
                                        {...registerQuestion(
                                            'additionalCoverageAmountDontKnow'
                                        )}
                                        onChange={(event) => {
                                            setAdditionalCoverageAmountDontKnow(
                                                event.target.checked
                                            )
                                        }}
                                    />{' '}
                                    I don't know yet
                                </label>
                            </fieldset>
                            <fieldset className="fieldset">
                                <h3>How much will it cost?</h3>
                                {additionalCoverageCostDontKnow !== true && (
                                    <>
                                        <label className="label">
                                            Amount (USD) *
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="number"
                                            {...registerQuestion(
                                                'additionalCoverageCost'
                                            )}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}

                                <label className="label label--radio">
                                    <input
                                        type="checkbox"
                                        required={false}
                                        value="1"
                                        {...registerQuestion(
                                            'additionalCoverageCostDontKnow'
                                        )}
                                        onChange={(event) => {
                                            setAdditionalCoverageCostDontKnow(
                                                event.target.checked
                                            )
                                        }}
                                    />{' '}
                                    I don't know yet
                                </label>
                            </fieldset>
                            <fieldset className="fieldset">
                                <h2>Which service are they using?</h2>
                                {depositServiceOptions.map((item, key) => (
                                    <label
                                        key={key}
                                        className="label label--radio"
                                    >
                                        <input
                                            type="radio"
                                            required={true}
                                            value={item.key}
                                            {...registerQuestion(
                                                'depositService'
                                            )}
                                            onClick={() =>
                                                setDepositService(item.key)
                                            }
                                        />{' '}
                                        {item.label}
                                    </label>
                                ))}
                                {depositService === 'other' && (
                                    <>
                                        <label className="label">
                                            Which one?
                                        </label>
                                        <input
                                            required={true}
                                            className="input"
                                            type="text"
                                            {...registerQuestion(
                                                'depositServiceText'
                                            )}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}
                            </fieldset>
                        </>
                    )}
                    <div className="prevnext">
                        <Link className="button" to="/preview">
                            Back
                        </Link>
                        <button className="button" type="submit">
                            Continue
                        </button>
                    </div>
                </form>
            )}
        </>
    )
}
